import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import Tabs from '@/pages/Tabs.vue'
import SignIn from '@/pages/auth/SignIn.vue'
import store from '@/store'

const routes: Array<RouteRecordRaw> = [
  {
    name: 'home',
    path: '/',
    redirect: '/tabs/orders',
  },
  {
    name: 'auth.signin',
    path: '/auth/signin',
    component: SignIn,
  },
  {
    path: '/tabs/',
    component: Tabs,
    children: [
      {
        path: '',
        redirect: '/tabs/orders',
      },
      {
        name: 'orders.index',
        path: 'orders',
        component: () => import('@/pages/Orders.vue'),
      },
      {
        name: 'profile',
        path: 'profile',
        component: () => import('@/pages/Profile.vue'),
      },
    ],
  },
]

const router = createRouter({
  routes,
  history: createWebHistory(process.env.BASE_URL),
})

router.beforeEach(async (to, from, next) => {
  if (!store.getters['auth/initialized']) {
    console.log('[router] Auth is not yet initialized, dispatching.')
    await store.dispatch('auth/init')
  }

  if (to.name !== 'auth.signin' && !store.getters['auth/authenticated']) {
    console.log('[router] Cancelling navigation & redirecting to auth page.')
    return next({ name: 'auth.signin' })
  }

  console.log('[router] Checking auth state from store', {
    authenticated: store.getters['auth/authenticated'],
    initialized: store.getters['auth/initialized'],
  })

  next()
})

export default router
