const config: any = {
  api: {
    baseUrl: process.env.VUE_APP_BASE_API_URI,

    inclusions: {
      stores: 'media,settings',
      profile: 'employees.city,employees.stocks,employees.store.media,employees.store.settings,employees.store.cities.stocks',
      orders: [
        'variants.variant.external_identifier', 'variants.product.external_identifier',
        'variants.product.media', 'variants.product.categories', 'variants.variant.option_values.option',
        'variants.modifiers.modifier.external_identifier', 'variants.modifiers.modifiers_group.external_identifier',
        'city', 'stock', 'customer', 'delivery_address', 'statuses.status', 'statuses.user', 'payments',
        'statuses_progress', 'deliver_at_local', 'preview_url',
      ].join(','),
    },

    authScopes: [
      'stores.manage', 'stores.users.manage', 'stores.notifications.manage', 'stores.webhooks.manage',
      'stores.loyalty.manage', 'stores.undermine.manage', 'sales_channels.manage',
      'catalog.manage', 'products.manage', 'orders.manage', 'customers.manage', 'content.manage', 'profile.manage',
      'profile.addresses.manage',
    ].join(','),
  },

  storage: {
    prefix: process.env.VUE_APP_STORAGE_PREFIX,
  },
}

export default config
