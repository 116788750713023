
import { defineComponent } from 'vue'
import { IonButton, IonContent, IonInput, IonItem, IonLabel, IonPage, IonAvatar, toastController } from '@ionic/vue'
import { AxiosResponse } from 'axios'
import { mapActions, mapGetters } from 'vuex'
import axios from '@/zenky'
import config from '@/zenky/config'
import errors from '@/zenky/errors'
import Store from '@/zenky/models/store/Store'
import images from '@/zenky/images'
import ImageType from '@/zenky/enums/ImageType'
import HasInAppBrowser from '@/mixins/HasInAppBrowser'

export default defineComponent({
  mixins: [HasInAppBrowser],

  components: {
    IonPage,
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonButton,
    IonAvatar,
  },

  computed: {
    ...mapGetters({
      stores: 'auth/stores',
    }),
  },

  mounted() {
    const query: any = this.$route.query

    if (typeof query.loggedOut !== 'undefined' && query.loggedOut) {
      this.displayLoggedOutToast()
    }
  },

  methods: {
    ...mapActions({
      loadStores: 'auth/loadStores',
      selectStore: 'auth/selectStore',
      loadUser: 'auth/loadUser',
    }),

    async displayLoggedOutToast() {
      const toast = await toastController.create({
        message: 'Вы вышли из аккаунта.',
        duration: 2000,
        color: 'primary',
        cssClass: 'text-white',
      })

      await toast.present()
    },

    async signIn() {
      if (this.formIsBusy) {
        return
      } else if (!this.validateForm()) {
        return
      }

      this.formIsBusy = true

      try {
        const response = (await axios.post(`/login?scopes=${config.api.authScopes}`, this.form)).data.data

        await this.processSignInResponse(response)

        this.formIsBusy = false
      } catch (e) {
        this.displayError(e.response || null)
        this.formIsBusy = false
      }
    },

    validateForm() {
      this.errors.phone = ''
      this.errors.password = ''

      if (!this.form.phone.length) {
        this.errors.phone = 'Нужно ввести номер телефона.'

        return false
      } else if (!this.form.password.length) {
        this.errors.password = 'Нужно ввести пароль.'

        return false
      }

      return true
    },

    displayError(response: AxiosResponse|null) {
      const error = errors.getError(response)

      if (error !== null) {
        this.errors.phone = error.message

        return
      }

      this.errors.phone = errors.getValidationError(response, 'phone') || ''
      this.errors.password = errors.getValidationError(response, 'password') || ''
    },

    async processSignInResponse(response: any) {
      if (!response.token) {
        this.errors.phone = 'Не удалось войти в аккаунт. Повторите попытку.'

        return
      }

      await this.loadStores(response.token)
      this.stage = 'stores'
    },

    async selectActiveStore(store: Store) {
      if (this.storeIsSelecting) {
        return
      }

      this.storeIsSelecting = true
      await this.selectStore(store)

      const user = await this.loadUser()
      console.log(user)

      this.storeIsSelecting = false

      if (user !== null) {
        await this.$router.replace({ name: 'home' })

        return
      }
    },

    storeLogoUrl(store: Store) {
      if (!store.media || !store.media.square_logo || !store.media.square_logo.images) {
        return this.defaultLogo
      }

      return images.getFirstImageUrl(store.media.square_logo, [ImageType.LARGE, ImageType.MEDIUM]) || this.defaultLogo
    },

    reset() {
      this.stage = 'signin'
      this.formIsBusy = false
      this.storeIsSelecting = false
      this.form.phone = ''
      this.form.password = ''
      this.errors.phone = ''
      this.errors.password = ''
    },
  },

  data() {
    return {
      stage: 'signin',

      formIsBusy: false,

      storeIsSelecting: false,

      form: {
        phone: '',
        phone_country: 'RU',
        password: '',
      },

      errors: {
        phone: '',
        password: '',
      },

      defaultLogo: require('@/assets/images/store.svg'),
    }
  }
})
