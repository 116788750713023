enum ImageType {
  PLACEHOLDER = 'placeholder',
  PLACEHOLDER_WEB = 'placeholder_webp',
  THUMB = 'thumb',
  THUMB_WEB = 'thumb_webp',
  MEDIUM = 'medium',
  MEDIUM_WEB = 'medium_webp',
  LARGE = 'large',
  LARGE_WEB = 'large_webp',
  XLARGE = 'xlarge',
  XLARGE_WEB = 'xlarge_webp',
  HD = 'hd',
  HD_WEB = 'hd_webp',
}

export default ImageType
