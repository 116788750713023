import Media from '@/zenky/models/common/Media'
import ImageType from "@/zenky/enums/ImageType";

export default {
  getFirstImageUrl(media: Media, types: ImageType[]): string|null {
    if (!media.images) {
      return null
    }

    for (const i in types) {
      const image = media.images[types[i]]

      if (image === null) {
        continue
      } else if (image.url === null) {
        continue
      }

      return image.url
    }

    return null
  }
}
