
import { IonApp, IonRouterOutlet } from '@ionic/vue'
import { defineComponent, ref, provide } from 'vue'

export default defineComponent({
  components: {
    IonApp,
    IonRouterOutlet
  },

  setup() {
    const routerOuteletRef = ref(null)

    provide('routerOutlet', routerOuteletRef)

    return {
      routerOuteletRef,
    }
  },
});
