import { AxiosResponse } from 'axios'
import ApiError from '@/zenky/models/api/ApiError'

export default {
  getError(response: AxiosResponse|null): ApiError|null {
    if (response === null) {
      return null
    }

    return response.data.error || null
  },

  getMessage(response: AxiosResponse|null, defaultMessage: string): string {
    const error = this.getError(response)

    if (!error || !error.message) {
      return defaultMessage
    }

    return error.message
  },

  getValidationError(response: AxiosResponse|null, key: string|null): string|null {
    if (response === null) {
      return null
    }

    const errors = response.data.errors
    const keys = Object.keys(errors)

    if (!keys.length) {
      return null
    } else if (key !== null && (typeof errors[key] !== 'object' || !Array.isArray(errors[key]) || !errors[key].length)) {
      return null
    }

    const actualKey = key ? key : keys[0]

    return errors[actualKey][0]
  }
}
