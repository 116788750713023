import axios from '@/zenky'
import config from '@/zenky/config'
import OrderStatusTransition from '@/zenky/models/orders/statuses/OrderStatusTransition'
import OrderStatus from '@/zenky/models/orders/statuses/OrderStatus'

export default {
  namespaced: true,

  state: () => ({
    loading: false,
    orders: [],
    statuses: [],
    pagination: null,
    statusUpdates: {},
    transitions: [],
  }),

  getters: {
    loading(state: any): boolean {
      return state.loading
    },

    statuses(state: any): OrderStatus[] {
      return state.statuses
    },

    orders(state: any): any[] {
      return state.orders
    },

    transitions(state: any): OrderStatusTransition[] {
      return state.transitions
    },

    pagination(state: any): any {
      return state.pagination
    },

    statusUpdates(state: any): any {
      return state.statusUpdates
    },
  },

  mutations: {
    setLoadingState(state: any, loading: boolean) {
      state.loading = loading
    },

    setStatuses(state: any, statuses: any[]) {
      state.statuses = statuses
    },

    appendOrders(state: any, orders: any[]) {
      orders.forEach(order => state.orders.push(order))
    },

    replaceOrders(state: any, orders: any[]) {
      state.orders = orders
    },

    setPagination(state: any, pagination: any) {
      state.pagination = pagination
    },

    replaceOrder(state: any, order: any) {
      const index = state.orders.findIndex((current: any) => current.id === order.id)

      if (index === -1) {
        state.orders.push(order)

        return
      }

      state.orders[index] = order
    },

    setOrderStatusIsUpdating(state: any, { orderId, updating }: { orderId: string; updating: boolean }) {
      console.log(`[orders@setOrderStatusIsUpdating] Order ${orderId} is${updating ? '' : ' not'} updating.`)

      state.statusUpdates[orderId] = updating
    },

    setTransitions(state: any, transitions: OrderStatusTransition[]) {
      state.transitions = transitions
    },
  },

  actions: {
    async loadStatuses({ commit }: { commit: Function }) {
      try {
        const statuses = (await axios.get('order-statuses?count=100')).data.data
          .filter((status: OrderStatus) => ['pending', 'awaiting_payment'].indexOf(status.kind.id) === -1)

        commit('setStatuses', statuses)

        return statuses
      } catch (e) {
        console.error('[orders@loadStatuses] Got error while trying to load statuses.', {
          error: e,
        })
      }

      return []
    },

    async loadTransitions({ commit }: { commit: Function }) {
      try {
        const transitions = (await axios.get('order-statuses/transitions')).data.data
        commit('setTransitions', transitions)

        return transitions
      } catch (e) {
        console.error('[transitions@loadTransitions] Got error while trying to load transitions.', {
          error: e,
        })
      }

      return []
    },

    async loadOrders({ commit }: { commit: Function }, { params, replace }: { params: any; replace: boolean }) {
      commit('setLoadingState', true)

      try {
        params.with = config.api.inclusions.orders
        params.order_by = '-submitted_at'
        const response = (await axios.get(`orders`, { params })).data

        if (replace) {
          commit('replaceOrders', response.data)
        } else {
          commit('appendOrders', response.data)
        }

        commit('setPagination', response.meta.pagination)
        commit('setLoadingState', false)

        return response.data
      } catch (e) {
        console.error('[orders@loadOrders] Got error while trying to load orders.', {
          params,
          replace,
          error: e,
        })

        throw e
      }
    },

    async updateOrderStatus(
      { commit, dispatch }: { commit: Function; dispatch: Function },
      { orderId, statusId, meta }: { orderId: string; statusId: string; meta: any }
    ) {
      commit('setOrderStatusIsUpdating', { orderId, updating: true })

      try {
        console.log('[orders@updateOrderStatus] Sending status update request')

        const order = (await axios.post(`orders/${orderId}/status`, { order_status_id: statusId, meta })).data.data

        console.log('[orders@updateOrderStatus] Status update request completed')

        dispatch('reloadOrder', order.id)
      } catch (e) {
        commit('setOrderStatusIsUpdating', { orderId, updating: false })

        console.log('[orders@updateOrderStatus] Got error while trying to update order status.', {
          orderId,
          statusId,
          error: e,
        })

        throw e
      }
    },

    async reloadOrder({ commit }: { commit: Function }, orderId: string ) {
      try {
        console.log('[orders@reloadOrder] Reloading order', { orderId })
        const inclusions = config.api.inclusions.orders
        const order = (await axios.get(`orders/${orderId}?with=${inclusions}`)).data.data

        console.log('[orders@reloadOrder] Order has been reloaded', { orderId })
        commit('replaceOrder', order)
        commit('setOrderStatusIsUpdating', { orderId, updating: false })

        return order
      } catch (e) {
        console.error('[orders@reloadOrder] Got error while trying to reload order.', {
          orderId,
          error: e,
        })
      }

      return null
    },
  },
}
