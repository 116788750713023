import { Storage } from '@ionic/storage'
import config from '@/zenky/config'

const store = new Storage()
store.create()

export default {
  getPrefixedKey(key: string): string {
    return `${config.storage.prefix}:${key}`
  },

  async getApiToken(): Promise<string|null> {
    return await store.get(this.getPrefixedKey('apiToken'))
  },

  async setApiToken(token: string): Promise<boolean> {
    await store.set(this.getPrefixedKey('apiToken'), token)

    return true
  },

  async removeApiToken(): Promise<boolean> {
    await store.remove(this.getPrefixedKey('apiToken'))

    return true
  },

  async getStoreId(): Promise<string|null> {
    return await store.get(this.getPrefixedKey('storeId'))
  },

  async setStoreId(storeId: string): Promise<boolean> {
    await store.set(this.getPrefixedKey('storeId'), storeId)

    return true
  },

  async removeStoreId(): Promise<boolean> {
    await store.remove(this.getPrefixedKey('storeId'))

    return true
  },

  async getOrderStatusFilter(): Promise<string[]> {
    const statuses = await store.get(this.getPrefixedKey('orderStatusFilter'))

    if (!statuses || typeof statuses !== 'string') {
      return []
    }

    return statuses.split(',')
  },

  async setOrderStatusFilter(statuses: string[]): Promise<boolean> {
    await store.set(this.getPrefixedKey('orderStatusFilter'), statuses.join(','))

    return true
  },

  async removeOrderStatusFilter(): Promise<boolean> {
    await store.remove(this.getPrefixedKey('orderStatusFilter'))

    return true
  },

  async getOrderStocksFilter(): Promise<string[]> {
    const stocks = await store.get(this.getPrefixedKey('orderStocksFilter'))

    if (!stocks || typeof stocks !== 'string') {
      return []
    }

    return stocks.split(',')
  },

  async setOrderStocksFilter(stocks: string[]): Promise<boolean> {
    await store.set(this.getPrefixedKey('orderStocksFilter'), stocks.join(','))

    return true
  },

  async removeOrderStocksFilter(): Promise<boolean> {
    await store.remove(this.getPrefixedKey('orderStocksFilter'))

    return true
  },
}
