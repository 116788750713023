import axios from 'axios'
import config from './config'

const headers: {[index: string]: any} = {
  'Accept': 'application/json',
  'X-Zenky-Client': 'mobile_cashier',
  'X-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
}

const instance = axios.create({
  headers,
  baseURL: config.api.baseUrl,
})

export default instance
