import axios from '@/zenky'
import config from '@/zenky/config'
import storage from '@/zenky/storage'
import Store from '@/zenky/models/store/Store'
import Employee from "@/zenky/models/store/Employee";

export default {
  namespaced: true,

  state: () => ({
    initialized: false,
    token: null,
    user: null,
    employee: null,
    store: null,
    stores: [],
  }),

  getters: {
    initialized(state: any): boolean {
      return state.initialized
    },

    authenticated(state: any): boolean {
      return state.token !== null && state.user !== null
    },

    token(state: any): string|null {
      return state.token
    },

    user(state: any): object|null {
      return state.user
    },

    employee(state: any): Employee|null {
      return state.employee
    },

    store(state: any): Store|null {
      return state.store
    },

    stores(state: any): Store[] {
      return state.stores
    },

    cities(state: any): any[] {
      return state.store.cities
    },
  },

  mutations: {
    setInitialized(state: any) {
      console.log('[auth@setInitialized] Setting initialization state.')

      state.initialized = true
    },

    setToken(state: any, token: string|null) {
      state.token = token
    },

    setUser(state: any, user: object|null) {
      state.user = user
    },

    setEmployee(state: any, employee: Employee|null) {
      state.employee = employee
    },

    setStore(state: any, store: Store|null) {
      state.store = store
    },

    setStores(state: any, stores: Store[]) {
      state.stores = stores
    },
  },

  actions: {
    async init({ commit, dispatch }: { commit: Function; dispatch: Function }) {
      console.log('[auth@init] Initializing auth state.')

      commit('setInitialized')
      commit('setToken', null)
      commit('setUser', null)
      commit('setEmployee', null)
      commit('setStore', null)
      commit('setStores', [])

      const token = await storage.getApiToken()
      const storeId = await storage.getStoreId()

      if (token && storeId) {
        return await dispatch('loadUser')
      }

      console.log('[auth@init] Token or storeId was not found.')

      return null
    },

    async loadStores({ commit }: { commit: Function }, token: string) {
      await storage.setApiToken(token)
      axios.defaults.headers['Authorization'] = `Bearer ${token}`

      try {
        const inclusions = config.api.inclusions.stores
        const stores = (await axios.get(`stores?employers=true&with=${inclusions}&count=100`)).data.data

        commit('setStores', stores)

        return stores
      } catch (e) {
        // Do nothing.
      }

      return []
    },

    async selectStore({ commit }: { commit: Function }, store: Store) {
      await storage.setStoreId(store.id)
      axios.defaults.headers['X-Store-Id'] = store.id

      commit('setStore', store)

      return store
    },

    async loadUser({ commit }: { commit: Function }) {
      const token = await storage.getApiToken()
      const storeId = await storage.getStoreId()

      if (!token || !storeId) {
        delete axios.defaults.headers['Authorization']
        delete axios.defaults.headers['X-Store-Id']

        console.error('[auth@loadUser] Token or storeId was not found.', { token, storeId })

        commit('setToken', null)
        commit('setUser', null)

        return null
      }

      console.log('[auth@loadUser] Loading user.')

      axios.defaults.headers['Authorization'] = `Bearer ${token}`
      axios.defaults.headers['X-Store-Id'] = storeId

      try {
        const inclusions = config.api.inclusions.profile
        const user = (await axios.get(`profile?with=${inclusions}`)).data.data
        const stores = user.employees.map((employee: Employee) => employee.store)

        commit('setToken', token)
        commit('setUser', user)
        commit('setStores', stores)

        const selectedEmployee = user.employees.find((employee: Employee) => employee.store_id === storeId)

        if (selectedEmployee) {
          commit('setStore', selectedEmployee.store)
          commit('setEmployee', selectedEmployee)
        } else if (user.employees.length > 0) {
          commit('setStore', user.employees[0].store)
          commit('setEmployee', user.employees[0])
        } else {
          console.log('[auth@loadUser] User has no stores.')

          commit('setToken', null)
          commit('setUser', null)

          return null
        }

        console.log('[auth@loadUser] User has been loaded.')

        return user
      } catch (e) {
        console.error('[auth@loadUser] Got error while trying to load user.', {
          error: e,
        })

        delete axios.defaults.headers['Authorization']
        delete axios.defaults.headers['X-Store-Id']

        commit('setToken', null)
        commit('setUser', null)
      }

      return null
    },
  },
}
