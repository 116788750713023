import { createStore } from 'vuex'
import auth from './modules/auth'
import orders from './modules/orders'

export default createStore({
  modules: {
    auth,
    orders,
  },
})
